<template>
  <div class="reset-failed-container">
    <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
    <h3>Password Reset Failed or Expired!</h3>
    <p>This password reset link has failed because the link has expired or is invalid.</p>
    <router-link to="/login">Click Here To Login</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uemail: ''
    }
  },
  created() {
    // Call the handleGoogleCallback action when the component is created
    //this.$store.dispatch('auth/handleGoogleCallback')
    //////console.log('below is our token', token)
  }
}
</script>

<style scoped>
.reset-failed-container {
  text-align: center;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #d9534f; /* Bootstrap's danger color */
}

p {
  margin-bottom: 20px;
}

router-link {
  color: #31708f; /* Bootstrap's info color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

router-link:hover {
  color: #245269;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}
</style>
